import React from 'react';
import { FaEnvelope } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { PiPhoneDisconnectFill } from "react-icons/pi";

const Contactus = () => {
  return (
    <div className="flex flex-col md:flex-row gap-8 md:gap-16 text-teal-800 px-4 md:px-16 py-8">
      
      {/* Contact Information */}
      <div className="max-w-full md:max-w-[500px] text-justify mx-auto text-lg">
        <address className="mt-4">
          <h3 className="text-xl font-semibold">Office Address:</h3>
          <p>Ok Enterprises</p>
          <p>Office No.2, Vaishanvi Apt,</p>
          <p>Near Modi Ganpati Mandir, Pune - 411030</p>
        </address>
        <br />
        <address className="mt-4">
          <h3 className="text-xl font-semibold"> Register Address:</h3>
          
          <p>Office No. A402,</p>
          <p>Aishwarya Laxmi Building,Dom Khel Rd, Wagholi,Pune–412207  </p>
        </address><br /><br />
        <br />
        <p className="flex items-center  text-xl md:text-base font-medium font-mono space-x-1"><PiPhoneDisconnectFill />: <a href="tel:020-29952242" className="hover:text-blue-600">020-29952242</a></p>
        <p className="flex items-center  text-xl md:text-base font-medium font-mono space-x-1"><FaPhoneAlt />: <a href="tel:+919579490386" className="hover:text-blue-600">+91 9579490386</a></p>
        <p className="flex items-center  text-xl md:text-base font-medium font-mono space-x-1"><FaPhoneAlt />: <a href="tel:+919579490386" className="hover:text-blue-600">+91 8668399095</a></p>
        <p className="flex items-center text-xl md:text-base font-medium font-serif space-x-1"><FaEnvelope /> <a href="mailto:sales@okenterprises.co.in" className="hover:text-blue-600">sales@okenterprises.co.in</a></p>
      </div>
      
      {/* Embedded Google Maps */}
      <div className="w-full max-w-full md:max-w-[600px] mx-auto">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d472.91249700667925!2d73.84798046395053!3d18.51534154251508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c15f2e9027c7%3A0x6878e14e11277eed!2sOK%20ENTERPRISES!5e0!3m2!1sen!2sin!4v1729877299659!5m2!1sen!2sin"
          width="100%"
          height="400"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="rounded-md shadow-md"
        ></iframe>
      </div>

    </div>
  );
};

export default Contactus;

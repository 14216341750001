
import React, {useEffect} from "react";

const Popup = ({ item, onClose }) => {

    useEffect(() => {
        // Disable scrolling when popup is open
        document.body.classList.add("overflow-hidden");
        return () => {
          // Re-enable scrolling when popup is closed
          document.body.classList.remove("overflow-hidden");
        };
      }, []);
    if (!item) return null;
  
    return (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg sm:max-w-xl md:max-w-2xl relative ">
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
          >
            &times;
          </button>
          <h2 className="text-xl font-semibold mb-4 text-center">{item.name}</h2>
          {/* <img src={item.img} alt={item.name} className="w-24 h-24 mx-auto mb-4" /> */}
          <p className=" mb-10">{item.info}</p>
          <div className="text-gray-700 ">
        <ul className="list-disc list-inside space-y-2">
            {item.product.map((product) => (
            <li key={product.id} className="text-base">
                {product.name}
            </li>
    ))}
  </ul>
</div>
        </div>
      </div>
    );
  };
  export default Popup;
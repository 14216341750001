import React from 'react';
import facebook from '../asset/facebook.png';
import whatsapp from '../asset/whatsapp.png';
import insta from '../asset/insta.png';
import { FaEnvelope } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="bg-gray-700 text-blue-500 py-10 font-medium">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center gap-5 px-4">
        {/* Company Info */}
        <div className="text-center md:text-left mb-4 md:mb-0">
          <h1 className="text-lg font-semibold">Ok Enterprises</h1>
          <p className="text-sm">© 2024 All rights reserved.</p>
        </div>

        {/* Navigation Links */}
        <div className="flex flex-col md:flex-row items-center md:space-x-4 text-sm">
          <a href="/" className="hover:text-pink-800 mb-2 md:mb-0">Home</a>
          <a href="/about" className="hover:text-pink-800 mb-2 md:mb-0">About</a>
          <a href="/product" className="hover:text-pink-800 mb-2 md:mb-0">Product</a>
          <a href="/contact" className="hover:text-pink-800">Contact</a>
        </div>

        {/* Contact Info */}
        <div className="text-center md:text-right text-sm">
          <p> <a href="mailto:sales@okenterprises.co.in" className="hover:text-pink-800 flex items-center  text-xl md:text-base font-medium font-mono space-x-1"><FaEnvelope />sales@okenterprises.co.in</a></p>
          <p><a href="tel:+919579490386" className="hover:text-pink-800 flex items-center  text-xl md:text-base font-medium font-mono space-x-1"><FaPhoneAlt />+91 9579490386</a></p>
        </div>

        {/* Social Media Links */}
        <div className="flex flex-col md:flex-row items-center space-x-4 text-center mt-4 md:mt-0">
          <span className="text-sm font-semibold">Follow Us</span>
          <a href="https://www.instagram.com/" className="hover:text-pink-800">
            <img src={insta} alt="Instagram" className="w-[30px] h-[30px]" />
          </a>
          <a href="https://www.facebook.com/sandeep.kavane.7" className="hover:text-pink-800">
            <img src={facebook} alt="Facebook" className="w-[30px] h-[30px]" />
          </a>
          <a href="https://wa.me/919579490386" className="hover:text-pink-800">
            <img src={whatsapp} alt="WhatsApp" className="w-[30px] h-[30px]" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import './App.css';
import Home from './Component/Home';
import Product from './Component/Product';
import Aboutus from './Component/Aboutus';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Contact from './Component/Contact';
import logo from './asset/logook.jpeg'
import Footer from './Component/Footer';
import { FaEnvelope } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Router>
        <div className="flex flex-col">
          <header className="flex flex-col md:flex-row items-center md:justify-between w-full px-4 py-5 shadow-md">
            <div className="flex items-center">
              <img
                className="w-[140px] h-16 md:w-[170px] md:h-20 shadow-md"
                src={logo}
                alt="Ok Enterprises"
              />
            </div>
            <ul className="flex flex-col md:flex-row items-center md:w-full mt-4 md:mt-0">
              <li className="text-black px-4 py-2 font-medium font-serif">
                <Link to="/">Home</Link>
              </li>
              <li className="text-black px-4 py-2 font-medium font-serif">
                <Link to="about">About Us</Link>
              </li>
              <li className="text-black px-4 py-2 font-medium font-serif">
                <Link to="Product">Product</Link>
              </li>
              <li className="text-black px-4 py-2 font-medium font-serif">
                <Link to="contact">Contact Us</Link>
              </li>
            </ul>
            <div className="flex flex-col items-center md:items-end md:ml-auto text-center md:text-right mt-4 md:mt-0">
              <span className="flex items-center text-blue-600 text-sm md:text-base font-medium font-serif space-x-1">
              <FaEnvelope />sales@okenterprises.co.in
              </span>
              <span className="text-sm font-mono flex items-center text-blue-600 md:text-base font-medium  space-x-1"><FaPhoneAlt />+919579490386</span>
            </div>
          </header>

          <main className="flex-grow p-4">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/about" element={<Aboutus />} />
              <Route path="/Product" element={<Product />} />
              <Route path="/contact" element={<Contact />} />
              {/* <Route path='/login' element={<Login />} /> */}
            </Routes>
          </main>
        </div>
      </Router>
      <Footer className="mt-auto" />
    </div>
  );
}

export default App;

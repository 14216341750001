import React from 'react';
import Microsoft from '../asset/microsoft.jpg';

const Aboutus = () => {
  return (
    <div className="flex flex-col md:flex-row py-10 px-4 md:px-20">
      
      {/* Text Section */}
      <div className="flex flex-col font-serif mb-8 md:mb-0 md:mr-8">
        <h2 className="text-center text-xl font-semibold mb-4">
          !! Welcome To Ok Enterprises !!
        </h2>
        
        <p className="max-w-full md:max-w-[450px] text-justify mx-auto mb-3">
          OK Enterprises, incorporated in 2020, started in Pune as resellers of Antivirus Software, Microsoft Office, Windows Licenses, etc. Our primary focus has been on Computer Software.
        </p>
        
        <p className="max-w-full md:max-w-[450px] text-justify mx-auto mb-3">
          As technology advanced, we expanded our services to include Laptops, Desktops, SSDs, and SMB products. Today, we serve all over Maharashtra. Our satisfied clients range from small dealers to large companies throughout the state. We believe in providing the best and most timely service to our customers.
        </p>
        
        <p className="max-w-full md:max-w-[450px] text-justify mx-auto mb-3">
          Our clientele includes businesses of all sizes, and we aim to continuously deliver the most reliable and efficient services across Maharashtra.
        </p>
      </div>
      
      {/* Image Section */}
      <div className="flex justify-center">
        <img 
          src={Microsoft} 
          alt="Microsoft products" 
          className="w-full md:w-[800px] h-auto max-h-[400px] object-cover rounded-lg shadow-md"
        />
      </div>
    </div>
  );
};

export default Aboutus;

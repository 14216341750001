import React, { useEffect, useState } from 'react'
import { useMemo } from 'react'
import qhimage from'../asset/BANNER2.jpg'

import avimg from'../asset/avimg.jpg'
import microsoft from'../asset/microsoft.jpg'
import microsoftbanner from'../asset/banner4.jpg'
import hardwareimg from'../asset/BANNER3.jpg'
import autocad from '../asset/autocad.jpg'
import shreelipi from'../asset/splash_nxt.png'
import seqrite from'../asset/seqrite.jpeg'
import sophos from'../asset/sophos.png'
import fortinate from '../asset/fortinate.jpg'
import sonicwall from '../asset/sonicwall.png'
import kundali from '../asset/kundali.jpg'
import laptop from'../asset/laptop.jpg'
import Desktop from'../asset/desktop1.jpg'
import ssd from '../asset/ssd.jpg'
import ups from '../asset/ups.png'
import monitor from'../asset/monitor.jpg'
import dell from'../asset/dell-refurbished-laptop.jpeg'
import hp from '../asset/hpref.jpeg'
import lenovo from '../asset/lenovo.jpg'
import asus from'../asset/asuss.jpg'
import acer from '../asset/acer.jpg'
import delldesktop from'../asset/delldesktop.jpg'
import hpdesktop from'../asset/hpdesktop.jpg'
import Popup from './popup'
import 'reactjs-popup/dist/index.css';

const Home = () => {

    const [currentindex,setCurrentindex]=useState(0)
    const intervaltime=5000

    const SMB=[
      {
        id:1,
        name:"Seqrite",
        img:seqrite,
        info:"Seqrite, a leading enterprise cybersecurity solutions provider and  delivers comprehensive protection against the latest cyber threats. Products List",
        product:
         [
        
      { id:1, name:"Data Privacy"},
      { id:2, name:"Endpoint Protection-premium"},
      { id:3, name:"Endpoint Protection-cloud"},
      { id:4, name:"Endpoint Detection and Response"},
      { id:5, name:"Extended Detection and Response"},
      { id:6, name:"Enterprise Mobility Management"},
      { id:7, name:"workspace"},
      { id:8, name:"Zero Trust network Access"},
      
      
      ]

      },
      {
        id:2,
        name:"Sophos",
        img:sophos,
        info:"Sophos detection and response, cloud, firewall, and managed services solutions provide unified threat management against emerging risks.",
        product:
         [
        
      { id:1, name:"Endpoint Security"},
      { id:2, name:"Email Security"},
      { id:3, name:"Network Security"},
      { id:4, name:" Network Infrastructure"},
      { id:5, name:" Cloud Security"},
      { id:6, name:" Sophos Home Premimum"},
      
      
      
      ]
      },
      {
        id:3,
        name:"Fortinate",
        img:fortinate,
        info:" single source management and control for endpoints, networks and cloud  ",
        product:
         [
        
      { id:1, name:"Network Security"},
      { id:2, name:"Unified SASE"},
      { id:3, name:"Security Operations"},
      { id:4, name:"Enterprise Networking"},
      { id:5, name:"Cloud Security"},
      { id:6, name:"Operational Technology"},
      
      
      
      ]

      },
      {
        id:4,
        name:"SonicWall",
        img:sonicwall,
        info:"SonicWall provides cybersecurity products, services and support for business.",
        product:
         [
        
      { id:1, name:"Network security"},
      { id:2, name:"Secure Access Service Edge (SASE)"},
      { id:3, name:"Threat Protection"},
      { id:4, name:"MANAGED XDR"},
      { id:5, name:"Email Security"},
      { id:6, name:"Secure Access"},
      
      
      
      ]

      }
   
      ]
    const software=[
      {
        id:1,
        name:"Antivirus",
        img:avimg,
        product:
         [
        
      { id:1, name:"Quick Heal"},
      { id:2, name:"Kaspersky"},
      { id:3, name:"NetProtector"},
      { id:4, name:"McAfee"},
      { id:5, name:"Norton"},
      { id:6, name:"Escan"},
      { id:7, name:"Eset"},
     
      ]
      },
      {
        id:2,
        name:"Microsoft",
        img:microsoft,
        product:
         [
        
      { id:1, name:"Windows OS"},
      { id:2, name:"Office 365"},
      { id:3, name:" Office Home & Business"},
      { id:4, name:" Office Home & student"},
     
      
      
      
      ]
      },
      {
        id:3,
        name:"Autocad",
        img:autocad,
        product:
         [
        
      { id:1, name:"Design and annotate 2D geometry and 3D models with solids, surfaces, and mesh objects Automate drafting tasks to place objects with AI, compare drawings, create schedules, publish layouts, and moreMaximize productivity with customized workspaces, AutoLISP, APIs, and apps"},
     
    ]

      },
      {
        id:4,
        name:"Shree-lipi",
        img:shreelipi,
        product:
         [
        
      { id:1, name:"Enhanced and user-friendly Conversion utility with RTF, TXT, Doc file conversion option"},
      { id:2, name:"Spell check for two-byte Font layout support (SLX and Unicode)"},
      { id:3, name:"Smart matra and validate matra for Indian language"},
      { id:4, name:"Font installation available with Family"},
      { id:5, name:"Avishkar Plus for SLX font layout for giving catchy effects to your advertisements, titles"},
      { id:6, name:"Hyphenation support for South Indian languages for SLX and Unicode font layout"},
      
      ]

      },
      {
        id:5,
        name:"Kundali",
        img:kundali,
        product:
         [
        
      { id:1, name:"Kismat - 2025 is an absolute software solution for Horoscope Marriage Compatibility, Ayanamshas, Panchangs, Dashas, Gochars, Varshaphals and much  more."},
     
      
      
      ]

      }
      ]
      const hardware=[
        {
          id:1,
          name:"Laptop",
          img:laptop
  
        },
        {
          id:2,
          name:"Desktop",
          img:Desktop
        },
        {
          id:3,
          name:"SSD",
          img:ssd
  
        },
        {
          id:4,
          name:"UPS",
          img:ups
  
        },
        {
          id:5,
          name:"Monitor",
          img:monitor
  
        }
        ]

        const Laptop=[
          {
            id:1,
            name:"Dell",
            img:dell
    
          },
          {
            id:2,
            name:"HP",
            img:hp
          },
          {
            id:3,
            name:"lenovo",
            img:lenovo
    
          },
          {
            id:4,
            name:"Asus",
            img:asus
    
          },
          {
            id:5,
            name:"Acer",
            img:acer
    
          }
          ]
          const desktop=[
            {
            id:1,
            name:"Dell",
            img:delldesktop
          },
          {
            id:2,
            name:"Hp",
            img:hpdesktop
          }
        ]
  
    const images = useMemo(() => [
        qhimage,
        microsoftbanner,
      hardwareimg
    
        // Add more image URLs as needed
      ], [])
      useEffect(()=>{
        const intervalid = setInterval(() => {
            setCurrentindex((previndex)=>(previndex+1)%images.length)
            console.log("hi");
            
        }, intervaltime);
        return ()=>clearInterval(intervalid)

      },[images,intervaltime])
      const [selectedCategory, setSelectedCategory] = useState('SMB');
      const [selectedref, setSelectedref] = useState('laptop');
      const [clickeditem, setClickeditem] = useState(false);
      const [selectedSMB, setselectedSMB] = useState(null);
      const [selectedsoftware, setselectedSoftware] = useState(null);
      
      const handleClicksmb = () => {
        setSelectedCategory('SMB');
      };

  const handleClick = () => {
    setSelectedCategory('software');
  };

  const handleClickHardware = () => {
    setSelectedCategory('hardware');
   
  };
  const handleClicklaptop = () => {
    setSelectedref('laptop');
   
  };
  const handleClickdesktop = () => {
    setSelectedref('desktop');
   
  };

  const handleclickpopup=(name,id)=>{
    console.log(id);
    console.log(SMB.id);
    const selectedItem=SMB.find((items)=>items.id===id)
    if(selectedItem){
      setselectedSMB(selectedItem)
      setClickeditem(true)
    }
    
  
   
  }
  const softwareclickpopup=(name,id)=>{
    const selectedId=software.find((items)=>items.id===id)
    if(selectedId){
      setselectedSoftware(selectedId)

    }
  }
  
  useEffect(() => {
    console.log("Updated clickeditem:", clickeditem);
  }, [clickeditem]); 

  const closdePopup=()=>{
setselectedSMB(null)
setselectedSoftware(null)
  }
  return (
<div>
    <div>
        <img src={images[currentindex]} alt={`Slideshow ${currentindex+1}`} className=' w-full md:h-[500px]object-cover rounded-xl mb-8'/>
    </div>

    <div  className='flex flex-row mt-10 px-8 justify-evenly items-center space-x-10' >
      <h2 onClick={handleClicksmb}  className='cursor-pointer md:text-base font-semibold text-gray-700 bg-gray-300 py-3 md:px-6 rounded-full hover:bg-teal-600 hover:text-white transition duration-300 ease-in-out shadow-xl'>Security Solutions</h2>
        <h2 onClick={handleClick}  className='cursor-pointer md:text-base font-semibold text-gray-700 bg-gray-300 py-3 md:px-6 rounded-full hover:bg-teal-600 hover:text-white transition duration-300 ease-in-out shadow-xl'>Software</h2>
        
        <h2 onClick={handleClickHardware}  className='cursor-pointer md:text-base font-semibold text-gray-700 bg-gray-300 py-3 md:px-6 rounded-full hover:bg-teal-600 hover:text-white transition duration-300 ease-in-out shadow-xl'>Hardware</h2>
             
                        
    </div>
    <div>
    {selectedCategory==='software' && (
        <div className=' mt-10 px-4 md:px-8'> 
        <div className=' flex flex-wrap gap-[30px] justify-center items-center  bg-gray-50 py-4'>
        {

          software.map((obj, i) => (

            <div key={obj.id} className='sm:w-[45%] lg:w-[30%]'>

              <div className={`w-[70%] h-96 bg-white text-center mt-9 rounded-xl font-medium font-serif shadow-lg mx-auto
                ${i % 2 === 0 ? 'animate-slideLeft' : 'animate-slideRight'} 
                delay-${i * 2}s`}> {/* Alternate animations and delay */}

                <img src={obj.img} className=' w-full sm:h-56 rounded-xl mb-10' alt='' />

                <div className=' bg-teal-700 block w-[105%] h-10 -ml-2 -mt-2  py-3 text-white' >{obj.name}</div>
                <div className=' mt-4'>

                  
                  <div className=' flex flex-row mx-auto justify-evenly'>
                    <div className=' w-20 h-6 bg-pink-800 py-1 ml- mt-4 rounded text-xs  text-center cursor-pointer' >
                      <p className='  text-white' onClick={()=>softwareclickpopup(obj.name,obj.id)}>know more</p>

                    </div>
                    
                </div>
              </div>

            </div>

          
            </div>
        ))

     
        
        
      }</div> 
        
               
        {
      selectedsoftware && <Popup item={selectedsoftware} onClose={closdePopup}/>
   
    }
       
        
        
        </div>
      )
    }
    </div>

    <div>
        {selectedCategory==='hardware' &&(
         <div className=' mt-10 px-4 md:px-8'> 
         <div className=' flex flex-wrap gap-[30px] justify-center items-center bg-gray-50 py-4'>
            {
 
           hardware.map((obj, i) => (
 
             <div key={obj.id} className='sm:w-[45%] lg:w-[30%]'>
 
              <div className={`w-[70%] h-96 bg-white text-center mt-9 rounded-xl font-medium font-serif shadow-lg mx-auto
                ${i % 2 === 0 ? 'animate-slideLeft' : 'animate-slideRight'} 
                delay-${i * 2}s`}> {/* Alternate animations and delay */}

                <img src={obj.img} className=' w-full h-56 rounded-xl mb-10' alt='' />

                <div className=' bg-teal-700 block w-[105%] h-10 -ml-2 -mt-2  py-3 text-white' >{obj.name}</div>
                <div className=' mt-4'>

                  
                  <div className=' flex flex-row mx-auto justify-evenly'>
                    <div className=' w-20 h-6 bg-pink-800 py-1 ml- mt-4 rounded text-xs  text-center cursor-pointer' >
                      <p className='  text-white'>call for price</p>

                    </div>
                    
                </div>
                </div>

              </div>
 
           
             </div>
         ))
 
      
         
         
            }    
            </div> 
          </div>
          )}
      </div>
      <div>
        {selectedCategory==='SMB' && (
        <div className=' mt-10 px-4 md:px-8'> 
        <div className=' flex flex-wrap gap-[30px] justify-center items-center  bg-gray-50 py-4'>
        {

          SMB.map((obj, i) => (

            <div key={obj.id} className='sm:w-[45%] lg:w-[30%]'>

              <div className={`w-[70%] h-96 bg-white text-center mt-9 rounded-xl font-medium font-serif shadow-lg mx-auto
                ${i % 2 === 0 ? 'animate-slideLeft' : 'animate-slideRight'} 
                delay-${i * 2}s`}> {/* Alternate animations and delay */}

                <img src={obj.img} className=' w-full sm:h-56 rounded-xl mb-10' alt='' />

                <div className=' bg-teal-700 block w-[105%] h-10 -ml-2 -mt-2  py-3 text-white' >{obj.name}</div>
                <div className=' mt-4'>

                  
                  <div className=' flex flex-row mx-auto justify-evenly'>
                    <div className=' w-20 h-6 bg-pink-800 py-1 ml- mt-4 rounded text-xs  text-center cursor-pointer' >
                      <p className='  text-white' onClick={()=>handleclickpopup(obj.name,obj.id)}>know more</p>

                    </div>
                    
                </div>
              </div>

            </div>

          
            </div>
        ))

     
        
        
}</div>

    {
      selectedSMB && <Popup item={selectedSMB} onClose={closdePopup}/>
   
    }
         
    </div>
               
              )}
        </div>

        <div className=' flex flex-row justify-center items-center mt-20 bg-teal-500 h-40 rounded-md'>
        <div className=' w-48 text-center cursor-pointer text-xl font-semibold text-gray-700 bg-gray-300 py-3 px-6 rounded-sm'>
            Refurbished
        </div>


        </div>

        <div className="flex flex-wrap gap-6 justify-center items-center mt-10 px-4 md:px-8 ">
          <span className="cursor-pointer text-base font-semibold text-gray-700 bg-gray-300 py-3 px-4 md:px-6 rounded-full hover:bg-teal-600 hover:text-white transition duration-300 ease-in-out shadow-xl" onClick={handleClicklaptop}>Laptop</span>
            <span className="cursor-pointer text-base font-semibold text-gray-700 bg-gray-300 py-3 px-4 md:px-6 rounded-full hover:bg-teal-600 hover:text-white transition duration-300 ease-in-out shadow-xl" onClick={handleClickdesktop}>Desktop</span>
      </div>

        <div>
  
        {selectedref==='laptop' &&(
          
         <div className=' mt-10 px-4 md:px-8'> 
         <div className=' flex flex-wrap gap-[30px] justify-center items-center bg-gray-50 py-4'>
         {
 
           Laptop.map((obj, i) => (
 
             <div key={obj.id} className='w-full sm:w-[45%] lg:w-[30%]'>
 
              <div className={`w-[70%] h-96 bg-white text-center mt-9 rounded-xl font-medium font-serif shadow-lg mx-auto
                ${i % 2 === 0 ? 'animate-slideLeft' : 'animate-slideRight'} 
                delay-${i * 2}s`}> {/* Alternate animations and delay */}

                <img src={obj.img} className=' w-full sm:h-56 rounded-xl mb-10' alt='' />

                <div className=' bg-teal-700 block w-[105%] h-10 -ml-2 -mt-2  py-3 text-white' >{obj.name}</div>
                <div className=' mt-4'>

                  
                  <div className=' flex flex-row mx-auto justify-evenly'>
                    <div className=' w-20 h-6 bg-pink-800 py-1 ml- mt-4 rounded text-xs  text-center cursor-pointer' >
                      <p className='  text-white'>call for price</p>

                    </div>
                    
                </div>
              </div>

            </div>
 
           
             </div>
         ))
 
      
         
         
          }</div> 
         </div>
               
              )}
        </div>

        <div>
  
        {selectedref==='desktop' &&(
          
         <div className=' mt-10 px-4 md:px-8'> 
         <div className=' flex flex-wrap gap-[30px] justify-center items-center bg-gray-50 py-4'>
         {
 
           desktop.map((obj, i) => (
 
             <div key={obj.id} className='w-full sm:w-[45%] lg:w-[30%]'>
 
              <div className={`w-[70%] h-96 bg-white text-center mt-9 rounded-xl font-medium font-serif shadow-lg mx-auto
                ${i % 2 === 0 ? 'animate-slideLeft' : 'animate-slideRight'} 
                delay-${i * 2}s`}> {/* Alternate animations and delay */}

                <img src={obj.img} className=' w-full sm:h-56 rounded-xl mb-10' alt='' />

                <div className=' bg-teal-700 block w-[105%] h-10 -ml-2 -mt-2  py-3 text-white' >{obj.name}</div>
                <div className=' mt-4'>

                  
                  <div className=' flex flex-row mx-auto justify-evenly'>
                    <div className=' w-20 h-6 bg-pink-800 py-1 ml- mt-4 rounded text-xs  text-center cursor-pointer' >
                      <p className='  text-white'>call for price</p>

                    </div>
                    
                </div>
              </div>

            </div>
 
           
             </div>
         ))
 
      
         
         
 }</div> 
         
                
              
         
         
         </div>
               
              )}
        </div>
    
    
    </div>
        
    )
}

export default Home
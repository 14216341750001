import React from 'react';
// Import the JSON data
import data from '../Data/db.json';

const Products = () => {
  // Access the Sheet array from the imported data
  const products = data.Sheet;
  console.log(products);
  

  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3  justify-center items-center py-3'>
      {products && products.map((product) => (
        <div key={product.Id} className="flex justify-center">
          <div className='w-[60%] max-w-xs h-80 bg-white text-center mt-9 rounded-xl font-medium font-serif shadow-xl hover:shadow-2xl transition-shadow duration-300'>
            <img src={product.image} alt="" className='h-60 px-3' />
            <span className='text-black'>{product.Name}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Products;
